.App {
  text-align: center;
}


.slick-slide {
 
  padding: 0 10px;
}

.slick-prev{
  margin: -4px;
}
.slick-next{
  margin: -4px;
}


.button-top {
  position: fixed;
  bottom: 20px;
  right: 18px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


/* AÇILIŞ */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  animation: dot-animation 1.5s infinite ease-in-out;
}

.dot-1 {
  background-color: #007bff;
  animation-delay: 0s;
}

.dot-2 {
  background-color: #28a745;
  animation-delay: 0.5s;
}

.dot-3 {
  background-color: #ffc107;
  animation-delay: 1s;
}

@keyframes dot-animation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* AÇILIŞ */



/* ComponentFeatures */

.new-arrivals{
  padding-left: 40px;
  padding-right: 40px;
}

.menu-day{
  padding-left: 40px;
  padding-right: 40px;
}


.blog{
  padding-left: 40px;
  padding-right: 40px;
}

.categories{
  padding-left: 40px;
  padding-right: 40px;
}

.contact{
  padding-left: 40px;
  padding-right: 40px;
}


/* ComponentFeatures */


/* Input */
input{
  padding: 16px;

}

textarea{
  padding-left: 10px;
  padding-top: 4px;
}
/* Input */

.inner-border {
  border: 2px dashed red; /* İç border */
  border-radius: 50%; /* Kenarları yuvarlat */
  padding: 10px; /* İç boşluğu ayarla */
  display: inline-block;
  
  width: 180px;
  height: 180px;
  transform: translate(-85px, 0px);

}

@keyframes spin {
  100% { 
    transform: rotate(360deg);
  }
}


